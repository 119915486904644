import posthog from "posthog-js";

export enum PostHostEventsEnum {
  LOGIN = "Login Realizado",
  CREATE_TAG = "Criou uma TAG",
  EDIT_TAG = "Editou uma TAG",
  CREATE_PORTAL_CREDENTIAL = "Criou uma Credencial",
  EDIT_PORTAL_CREDENTIAL = "Editou uma Credencial",
  DELETE_PORTAL_CREDENTIAL = "Deletou uma Credencial",
  CREATE_VEHICLE = "Criou um Veículo",
  EDIT_VEHICLE = "Editou um Veículo",
  PUBLISH_VEHICLE = "Publicou um Veículo",
  UNPUBLISH_VEHICLE = "Despublicou um Veículo",
  PARTIAL_UNPUBLISH_VEHICLE = "Despublicou Parciamente um Veículo",
}

export type SendPostHotEventType = {
  eventName: PostHostEventsEnum;
  email?: string | null;
  eventProperties?: Record<string, unknown>;
  identifyProperties?: Record<string, unknown>;
};

export const sendPostHogEvent = ({
  eventName,
  eventProperties,
  email,
  identifyProperties = {},
}: SendPostHotEventType): void => {
  if (email) posthog.identify(email, identifyProperties);

  posthog.capture(eventName, eventProperties);
};
